import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Category1 } from 'assets/icons'
import companyService from 'services/company-service'
import { getTKey } from 'utils/language'
import ProviderCard from './provider-card'

const ProvidersSection = () => {
	const { t } = useTranslation()

	const tKey = getTKey('home')

	const [companies, setCompanies] = useState<SearchResult[]>([])

	useEffect(() => {
		companyService
			.searchCompanies(undefined, undefined, undefined, 1, true)
			.then(res => setCompanies(res.data))
	}, [])

	return (
		<section className="py-12 px-4">
			<div className="text-center mb-10">
				<h5 className="text-[#D2998D] whitespace-nowrap font-semibold italic text-lg mb-4">
					{t(tKey('labels.trusted'))}
				</h5>
				<h1 className="text-[32px] md:text-[40px] font-bold text-[#2c2d6a] mb-4 leading-tight">
					{t(tKey('headings.popularProviders'))}
				</h1>
				<p className="text-md md:text-md text-gray-600 mb-10">{t(tKey('labels.topProviders'))}</p>
			</div>

			<div className="grid grid-cols-1 gap-y-10 gap-x-24 md:grid-cols-2 lg:grid-cols-3 max-w-7xl mx-auto">
				{companies?.slice(0, 9).map(company => (
					<ProviderCard
						key={company._id}
						id={company._id}
						city={company.city}
						zipCode={company.zip}
						imageUrl={company.slider_url ?? company.gallery[0] ?? Category1}
						title={company.company_name}
						totalReviews={company.total_reviews}
						description={company.about_company}
						category={company.company_type[0]}
						created={company.created}
						rating={company.average_rating}
					/>
				))}
			</div>
		</section>
	)
}

export default ProvidersSection
