import { XMarkIcon } from '@heroicons/react/24/outline'
import React from 'react'
import { Link } from 'react-router-dom'

import clsx from 'clsx'
import { AppHeader, DashboardHeader, HomeHeader } from 'components/app/header'
import { useAppDispatch, useAppSelector } from 'hooks'
import { useDocumentTitle } from 'hooks/useDocumentTitle'
import { hideBanner } from 'slices/banner'

type NavItem = {
	name: string
	icon?: JSX.Element
	href: string
	current?: boolean
}

interface AppLayoutProps {
	children: React.ReactNode
	title?: string
	onLogoClick?: () => void
	renderHeader?: boolean
	renderDashboardHeader?: boolean
	renderHomeHeader?: boolean
	navigation?: NavItem[]
	className?: string
	wrapperClassName?: string
}

export const AppLayout: React.FC<AppLayoutProps> = ({
	renderHeader = false,
	renderDashboardHeader = false,
	renderHomeHeader = false,
	onLogoClick,
	children,
	navigation,
	className,
	wrapperClassName,
	title
}) => {
	const dispatch = useAppDispatch()

	const auth = useAppSelector(state => state.auth)
	const showBanner = useAppSelector(state => state.banner)

	const docTitleText = title
		? `${title} | Styling Corner`
		: 'Styling Corner - Beauty, Kosmetik, Haare, Nails, Massagen, Wellness -und Spa, Online Termine'

	useDocumentTitle(docTitleText)

	return (
		<div className={clsx('relative', className)}>
			{!auth.accessToken && showBanner && (
				<div className="bg-[#E9F1F8] max-md:text-center max-md:pr-5 max-md:text-xs relative text-primary py-4 flex items-center gap-1 md:gap-5 justify-center">
					<p>
						<span className="text-indigo-600 mr-1 md:mr-2 md:text-lg font-bold">Incentives</span>
						Sichere dir exklusive Rabatte, indem du bei jeder Buchung Punkte sammelst!{' '}
						<Link className="hover:underline font-bold" to="/incentives">
							Erfahre mehr!
						</Link>
					</p>
					<XMarkIcon
						onClick={() => dispatch(hideBanner())}
						className="absolute right-1 md:right-3 size-4 shrink-0 md:size-6 cursor-pointer"
					/>
				</div>
			)}
			{renderHeader ? <AppHeader onLogoClick={onLogoClick} navigationItems={navigation} /> : null}
			{renderDashboardHeader ? <DashboardHeader navigationItems={navigation} /> : null}
			{renderHomeHeader ? <HomeHeader /> : null}
			<div className={clsx(wrapperClassName, { 'mt-0': renderDashboardHeader })}>{children}</div>
		</div>
	)
}
