import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import {
	ArticleLeftCircle,
	ArticleTopCircle,
	GiftImage,
	GiftSecondImage,
	MobileGiftImage
} from 'assets/icons'
import { ReactComponent as GiftThreeIcon } from 'assets/svgs/gift-three.svg'
import { ReactComponent as GiftTwoIcon } from 'assets/svgs/gift-two.svg'
import { ReactComponent as WorkTwoIcon } from 'assets/svgs/work-two.svg'
import { Footer } from 'components/app/footer'
import { AppLayout } from 'components/app/layout'
import { FAQ } from 'components/home/faq'
import { SubscriptionBox } from 'components/home/subscription-box'
import { useAppSelector } from 'hooks'
import useIsMobile from 'hooks/useIsMobile'
import { getTKey } from 'utils/language'

export const Gift = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const isMobile = useIsMobile()

	const tKey = getTKey('coupon')

	const auth = useAppSelector(state => state.auth)

	return (
		<AppLayout renderHomeHeader={!auth.accessToken} renderDashboardHeader={!!auth.accessToken}>
			<div className="relative bg-giftBackground h-[700px] md:h-[660px] py-10 px-3 md:py-[100px] bg-cover bg-center bg-no-repeat">
				<div className="flex flex-col items-center gap-[25px] md:gap-10">
					<div className="flex flex-col items-center gap-6 md:gap-[30px]">
						<h4 className="text-[#ED107A] max-md:text-center font-semibold text-lg md:text-sm">
							Schönheit schenken war noch nie so einfach!
						</h4>
						<div className="flex flex-col items-center gap-6">
							<h1 className="text-white max-md:text-center font-domine font-bold text-[32px] md:text-[56px]">
								Styling Corner Geschenkkarte
							</h1>

							<p className="text-white md:w-3/4 text-center max-md:text-sm">
								Überraschen Sie Ihre Liebsten mit einer Styling Corner Geschenkkarte – das perfekte
								Geschenk für jeden Anlass! Egal ob für Geburtstage, Jubiläen oder einfach als
								besondere Aufmerksamkeit – mit unserer Geschenkkarte treffen Sie immer den richtigen
								Geschmack.
							</p>
						</div>
					</div>

					<button
						onClick={() => navigate('/user/incentives')}
						className="bg-[#5964D3] max-md:mt-3 w-fit whitespace-nowrap text-white py-2.5 md:py-3 px-6 rounded-lg hover:bg-[#7D8AE6] focus:outline-none shadow-lg">
						{t(tKey('buttons.getStarted'))}
					</button>
				</div>
				<img
					src={GiftImage}
					className="max-md:hidden absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-64"
				/>
				<img
					src={MobileGiftImage}
					className="md:hidden absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-64"
				/>
			</div>

			<div className="flex items-center justify-center px-5 md:px-[120px] flex-col gap-10 md:gap-14 mt-[300px] md:mt-[350px]">
				<h1 className="text-center max-md:hidden font-domine font-bold text-[48px]">
					Warum die Styling Corner <br />
					Geschenkkarte?
				</h1>
				<h1 className="text-center md:hidden font-domine font-bold text-[32px]">
					Warum die Styling <br /> Corner Geschenkkarte?
				</h1>
				<div className="grid grid-cols-1 max-md:gap-4 md:grid-cols-3 items-end">
					<div className="bg-[#E9F1F8] md:h-[235px] flex flex-col gap-3 md:gap-5 py-8 md:py-10 px-5 md:px-[51px] border border-[#4C678033] max-md:rounded-[20px] md:rounded-tl-[20px] md:rounded-bl-[20px]">
						<h5 className="text-[#5964D3] font-domine text-center font-bold text-xl md:text-2xl">
							Flexibel einlösbar
						</h5>
						<p className="text-primary text-base md:text-xl text-center">
							Unsere Geschenkkarte kann bei allen teilnehmenden Beauty-Betrieben auf der Styling
							Corner Plattform eingelöst werden.
						</p>
					</div>
					<div className="bg-[#E9F1F8] md:bg-[#5964D3] flex flex-col gap-3 md:gap-5 py-8 md:py-10 px-5 md:px-[51px] border border-[#4C678033] md:h-[320px] max-md:rounded-[20px] rounded-t-[20px]">
						<h5 className="max-md:text-[#5964D3] text-white font-domine text-center font-bold text-xl md:text-2xl">
							Vielfalt erleben
						</h5>
						<p className="text-primary md:text-white text-base md:text-xl text-center">
							on Hairstyling und Kosmetik bis hin zu Wellness und Spa – die Beschenkten können aus
							einer Vielzahl von Dienstleistungen wählen.
						</p>
					</div>
					<div className="bg-[#E9F1F8] md:h-[235px] flex flex-col gap-3 md:gap-5 py-8 md:py-10 px-5 md:px-[51px] border border-[#4C678033] max-md:rounded-[20px] md:rounded-tr-[20px] md:rounded-br-[20px]">
						<h5 className="text-[#5964D3] font-domine text-center font-bold text-xl md:text-2xl">
							Einfach und bequem
						</h5>
						<p className="text-primary text-base md:text-xl text-center">
							Die Geschenkkarte ist digital verfügbar und kann sofort genutzt oder weitergegeben
							werden.
						</p>
					</div>
				</div>
			</div>

			<div className="md:pt-20 pt-14 px-6 md:px-[120px] flex flex-col gap-6 md:gap-[51px] items-center">
				<div className="flex max-md:flex-col max-md:gap-6 self-stretch items-center md:justify-between">
					<h1 className="text-primary md:basis-2/5 font-domine font-bold text-3xl md:text-5xl">
						So funktioniert's
					</h1>
					<p className="text-primary md:text-2xl md:basis-3/5">
						Jede Buchung bringt Sie näher an erstaunliche Rabatte. So schnell können Sie sie
						verdienen:
					</p>
				</div>
				<div className="max-md:flex max-md:flex-col-reverse md:grid md:grid-cols-2 gap-8 md:gap-[100px] items-center relative">
					<div className="flex relative flex-col gap-[60px]">
						<div className="absolute inset-0 -z-10 h-[70%] md:h-[75%] max-md:-translate-y-[65%] translate-x-[26px] top-1/2 -translate-y-1/2 w-1 border-r border-dashed border-[#7F9AB2]" />
						<div className="flex items-start gap-6 md:gap-[37px]">
							<WorkTwoIcon className="shrink-0" />
							<div className="flex flex-col gap-5">
								<h2 className="text-primary font-domine font-bold text-xl md:text-2xl">
									Geschenkkarte auswählen
								</h2>
								<p className="text-primary">Wählen Sie den gewünschten Betrag aus.</p>
							</div>
						</div>
						<div className="flex items-start gap-[37px]">
							<GiftTwoIcon className="shrink-0" />
							<div className="flex flex-col gap-5">
								<h2 className="text-primary font-domine font-bold text-xl md:text-2xl">
									Personalisieren
								</h2>
								<p className="text-primary">
									Fügen Sie eine persönliche Nachricht hinzu, um das Geschenk noch besonderer zu
									machen.
								</p>
							</div>
						</div>
						<div className="flex items-start gap-5 md:gap-[37px]">
							<GiftThreeIcon className="shrink-0" />
							<div className="flex flex-col gap-5">
								<h2 className="text-primary font-domine font-bold text-xl md:text-2xl">
									Verschenken
								</h2>
								<p className="text-primary">
									Senden Sie die Geschenkkarte per E-Mail oder drucken Sie sie aus.
								</p>
							</div>
						</div>
					</div>
					<img src={GiftSecondImage} alt="coupon-info" className="ml-auto" />
				</div>
			</div>

			<div className="relative mt-14 md:mt-[100px] flex flex-col items-center justify-between mx-6 md:mx-[120px] overflow-hidden bg-[#B790D8] max-md:px-6 md:px-12 py-14 rounded-[48px] font-semibold text-xl">
				<div className="flex flex-col relative z-10 items-center gap-4">
					<h2 className="text-white font-bold font-domine max-md:text-center text-3xl md:text-[40px]">
						Ein Geschenk, das immer passt
					</h2>
					<p className="text-white md:w-3/4 text-base md:text-[18px] text-center">
						Die Styling Corner Geschenkkarte ermöglicht es Ihren Liebsten, genau das auszuwählen,
						was sie sich wünschen. Es ist das ideale Geschenk, um Entspannung, Schönheit und
						Wohlbefinden zu schenken.
					</p>
				</div>
				<img src={ArticleLeftCircle} className="absolute top-0 left-0" />
				<img src={ArticleTopCircle} className="absolute bottom-0 right-5" />
			</div>
			<div className="my-14 md:my-[100px]">
				<FAQ />
			</div>
			<div className="mb-14">
				<SubscriptionBox type="coupon" />
			</div>
			<Footer />
		</AppLayout>
	)
}
