import axios from 'axios';
import { advertisementApiHost } from 'utils/host';

const getAllAdvertisements = (): Promise<Advertisement[]> => {
	return axios
		.get(`${advertisementApiHost}/advertisements`)
		.then(response => response.data);
};

const advertisementService = {
	getAllAdvertisements,
};

export default advertisementService;
