import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import advertisementService from 'services/advertisement-service';
import { getTKey } from 'utils/language';

const AdvertisementSection: React.FC = () => {
  const [advertisements, setAdvertisements] = useState<Advertisement[]>([]);
  const tKey = getTKey('home')

  useEffect(() => {
    advertisementService.getAllAdvertisements().then((response) => {
      setAdvertisements(response);
    });
  }, []);


return (
  <>
  {advertisements.length === 4 ? (
    <div className="container md:h-[950px] mx-auto py-8 px-5 md:px-[50px] md:py-[100px]">
    <div className="grid h-full md:grid-rows-2 md:grid-flow-col grid-cols-1 md:grid-cols-4 gap-4 md:gap-8">
        <div className="relative md:col-span-2 flex flex-col overflow-hidden md:row-span-2 rounded-[32px] h-full">
        <img
          src={advertisements[0]?.backgroundImage || ''}
          className="w-full h-full object-cover rounded-[32px]"
          alt={advertisements[0]?.title}
        />
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black/50 z-10" />
        <div className="absolute z-20 flex flex-col gap-3 left-6 bottom-24">
          <h2 style={{ color: advertisements[0]?.textColor || '#FFFFFF' }} className="text-[33px] leading-[40px] font-semibold">
            {advertisements[0]?.title}
          </h2>
          <p style={{ color: advertisements[0]?.textColor || '#FFFFFF' }} className="font-nunito text-[24px] leading-[40px]">
            {advertisements[0]?.overview}
          </p>
        </div>
        <button
          onClick={() => window.open(advertisements[0]?.link || '/', '_blank')}
          className="bg-[#5964D3] size-[61px] absolute flex items-center justify-center top-5 right-5 text-white rounded-full hover:bg-[#7D8AE6] focus:outline-none z-50"
        >
          <ArrowRightIcon className="-rotate-45 size-[33px]" />
        </button>
        <div className="absolute bottom-0 left-0 right-0 bg-primary/90 py-2.5 flex justify-center items-center rounded-b-[32px] z-50">
          <p className="text-white uppercase font-semibold">{t(tKey('labels.advertising'))}</p>
        </div>
      </div>
  
      <div className="relative bg-[#D39A8E80] w-full h-full rounded-[32px] overflow-hidden flex flex-col">
        <img
          src={advertisements[1]?.backgroundImage || ''}
          className="w-full h-full object-cover rounded-[32px]"
          alt={advertisements[1]?.title}
        />
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black/50 z-10" />
        <div className="absolute z-20 flex flex-col gap-3 left-6 bottom-24">
          <h2 style={{ color: advertisements[1]?.textColor || '#FFFFFF' }} className="text-[33px] leading-[40px] font-semibold">
            {advertisements[1]?.title}
          </h2>
          <p style={{ color: advertisements[1]?.textColor || '#FFFFFF' }} className="font-nunito text-[24px] leading-[40px]">
            {advertisements[1]?.overview}
          </p>
        </div>
        <button
          onClick={() => window.open(advertisements[1]?.link || '/', '_blank')}
          className="bg-[#5964D3] size-[61px] absolute flex items-center justify-center top-5 right-5 text-white rounded-full hover:bg-[#7D8AE6] focus:outline-none z-50"
        >
          <ArrowRightIcon className="-rotate-45 size-[33px]" />
        </button>
        <div className="absolute bottom-0 left-0 right-0 bg-primary/90 py-2.5 flex justify-center items-center rounded-b-[32px] z-50">
          <p className="text-white uppercase font-semibold">{t(tKey('labels.advertising'))}</p>
        </div>
      </div>
  
      <div className="relative bg-[#D39A8E80] w-full h-full rounded-[32px] overflow-hidden flex flex-col">
        <img
          src={advertisements[2]?.backgroundImage || ''}
          className="w-full h-full object-cover rounded-[32px]"
          alt={advertisements[2]?.title}
        />
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black/50 z-10" />
        <div className="absolute z-20 flex flex-col gap-3 left-6 bottom-24">
          <h2 style={{ color: advertisements[2]?.textColor || '#FFFFFF' }} className="text-[33px] leading-[40px] font-semibold">
            {advertisements[2]?.title}
          </h2>
          <p style={{ color: advertisements[2]?.textColor || '#FFFFFF' }} className="font-nunito text-[24px] leading-[40px]">
            {advertisements[2]?.overview}
          </p>
        </div>
        <button
          onClick={() => window.open(advertisements[2]?.link || '/', '_blank')}
          className="bg-[#5964D3] size-[61px] absolute flex items-center justify-center top-5 right-5 text-white rounded-full hover:bg-[#7D8AE6] focus:outline-none z-50"
        >
          <ArrowRightIcon className="-rotate-45 size-[33px]" />
        </button>
        <div className="absolute bottom-0 left-0 right-0 bg-primary/90 py-2.5 flex justify-center items-center rounded-b-[32px] z-50">
          <p className="text-white uppercase font-semibold">{t(tKey('labels.advertising'))}</p>
        </div>
      </div>
  
      <div className="relative rounded-[32px] overflow-hidden flex flex-col md:row-span-2">
        <img
          src={advertisements[3]?.backgroundImage || ''}
          className="w-full h-full object-cover rounded-[32px]"
          alt={advertisements[3]?.title}
        />
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black/50 z-10" />
        <div className="absolute z-20 flex flex-col gap-3 left-6 bottom-24">
          <h2 style={{ color: advertisements[3]?.textColor || '#FFFFFF' }} className="text-[33px] leading-[40px] font-semibold">
            {advertisements[3]?.title}
          </h2>
          <p style={{ color: advertisements[3]?.textColor || '#FFFFFF' }} className="font-nunito text-[24px] leading-[40px]">
            {advertisements[3]?.overview}
          </p>
        </div>
        <button
          onClick={() => window.open(advertisements[3]?.link || '/', '_blank')}
          className="bg-[#5964D3] size-[61px] absolute flex items-center justify-center top-5 right-5 text-white rounded-full hover:bg-[#7D8AE6] focus:outline-none z-50"
        >
          <ArrowRightIcon className="-rotate-45 size-[33px]" />
        </button>
        <div className="absolute bottom-0 left-0 right-0 bg-primary/90 py-2.5 flex justify-center items-center rounded-b-[32px] z-50">
          <p className="text-white uppercase font-semibold">{t(tKey('labels.advertising'))}</p>
        </div>
      </div>
  
    </div>
  </div>
  ): <></>}
    </>
  );
};

export default AdvertisementSection;
