import { UserIcon } from '@heroicons/react/20/solid'
import { CalendarIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaTiktok } from 'react-icons/fa'
import { Link, useParams } from 'react-router-dom'

import { DateTime } from 'luxon'

import { SocialInstagramIcon, SocialLinkedinIcon } from 'assets/icons'
import { Spinner } from 'components/animations/spinner'
import { Breadcrumb } from 'components/app/breadcrumb'
import { Footer } from 'components/app/footer'
import { AppLayout } from 'components/app/layout'
import ArticleSection from 'components/home/article-section'
import { useAppSelector } from 'hooks'
import articleService from 'services/articles-service'
import { getAppLang, getTKey } from 'utils/language'

export const ArticleDetails = () => {
	const { id: articleId } = useParams() as { id: string }
	const { t } = useTranslation()

	const tKey = getTKey('articles')
	const auth = useAppSelector(state => state.auth)

	const [article, setArticle] = useState<Article>()
	const [headings, setHeadings] = useState<{ id: string; content: string }[]>()
	const [isLoading, setIsLoading] = useState(true)

	const socialIcons = [
		{
			icon: SocialInstagramIcon,
			href: 'https://www.instagram.com/stylingcorner_schweiz/'
		},
		{
			icon: SocialLinkedinIcon,
			href: 'https://www.linkedin.com/company/styling-corner%C2%AE'
		}
	]

	useEffect(() => {
		if (articleId) {
			articleService
				.getArticleById(articleId)
				.then(res => {
					setArticle(res)
					const regex = /<h1><strong>(.*?)<\/strong><\/h1>/g
					const matches = []
					let match
					if (res.content) {
						while ((match = regex.exec(res.content)) !== null) {
							const content = match[1]
							matches.push({ id: `heading-${matches.length}`, content })
						}
						setHeadings(matches)
					}
				})
				.finally(() => setIsLoading(false))
		}
	}, [articleId])

	const scrollToElement = (id: string) => {
		const element = document.getElementById(id)
		if (element) {
			const offset = 20
			const bodyRect = document.body.getBoundingClientRect().top
			const elementRect = element.getBoundingClientRect().top
			const elementPosition = elementRect - bodyRect
			const offsetPosition = elementPosition - offset

			window.scrollTo({
				top: offsetPosition,
				behavior: 'smooth'
			})
		}
	}

	const processContent = (content: string) => {
		if (!content) return ''

		const parser = new DOMParser()
		const doc = parser.parseFromString(content, 'text/html')

		const h1Elements = doc.querySelectorAll('h1')

		h1Elements.forEach((h1, index) => {
			h1.id = `heading-${index}`

			const hasOnlyBrTags = Array.from(h1.childNodes).every(
				node =>
					node.nodeName === 'BR' || (node.nodeType === Node.TEXT_NODE && !node.textContent?.trim())
			)

			if (hasOnlyBrTags) {
				const wrapper = doc.createElement('div')

				h1.querySelectorAll('br').forEach(br => {
					const brWrapper = doc.createElement('span')
					brWrapper.appendChild(br.cloneNode())
					wrapper.appendChild(brWrapper)
				})

				h1.replaceWith(wrapper)
			}
		})

		return doc.body.innerHTML
	}

	if (isLoading) {
		return (
			<AppLayout renderHomeHeader={!auth.accessToken} renderDashboardHeader={!!auth.accessToken}>
				<div className="fixed top-1/2 -translate-y-1/2 h-full w-full left-1/2 -translate-x-1/2 bg-black bg-opacity-70 z-50">
					<div className="fixed top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2">
						<Spinner className=" h-8 w-8 mb-2" />
						<span className="whitespace-nowrap text-black -ml-9 text-xl animate-pulse">
							{t(tKey('labels.pleaseWait'))}
						</span>
					</div>
				</div>
			</AppLayout>
		)
	}

	return (
		<AppLayout
			renderHomeHeader={!auth.accessToken}
			renderDashboardHeader={!!auth.accessToken}
			className="min-h-screen bg-articleBackground bg-cover bg-top bg-no-repeat">
			<div className="pt-4">
				<div className="max-md:px-5 md:pl-[120px]">
					<Breadcrumb
						options={[
							{
								name: 'Articles',
								href: `/articles`,
								current: false
							},
							{
								name: article?.title as string,
								href: `/articles/${articleId}`,
								current: true
							}
						]}
						theme="dark"
					/>
				</div>
				<div className="grid md:px-[120px] grid-cols-1 gap-8 md:grid-cols-2 items-center mt-8 md:mt-[21px]">
					<div className="flex max-md:px-5 flex-col gap-3.5 md:gap-6">
						<h1 className="text-primary font-domine font-bold text-2xl leading-8 md:text-[40px] md:leading-[60px]">
							{article?.title}
						</h1>
						<div className="flex items-center gap-6">
							<div className="flex items-center gap-2.5">
								<CalendarIcon className="size-[18px] md:size-6 text-primary-light" />
								<p className="text-primary max-md:text-xs">
									{DateTime.fromISO(article?.dateUpdated as string)
										.setLocale(getAppLang())
										.toFormat('dd. LLLL yyyy')}
								</p>
							</div>
							<div className="flex items-center gap-2.5">
								<UserIcon className="size-[18px] md:size-6 text-primary-light" />
								<p className="text-primary max-md:text-xs">Styling Corner</p>
							</div>
						</div>
					</div>
					<img
						style={{
							borderImageSource:
								'linear-gradient(90deg, rgba(239, 51, 100, 0.4) 0%, rgba(89, 100, 211, 0.4) 100%);'
						}}
						src={article?.image}
						alt="article-image"
						className="border-[7px] max-md:border-l-0 max-md:border-r-0 h-[295px] md:h-[410px] md:rounded-[28px] w-full object-cover"
					/>
				</div>
				<div className="max-md:pt-10 px-5 md:py-14 md:px-[120px] flex max-md:flex-col-reverse items-start gap-8 md:gap-[60px]">
					<div className="flex flex-col w-full max-md:gap-8 md:basis-[77%]">
						<div
							className="w-full articleContent"
							dangerouslySetInnerHTML={{ __html: processContent(article?.content as string) }}
						/>
					</div>
					<div className="md:basis-[23%] md:ml-auto flex flex-col md:gap-12">
						<div className="flex items-center justify-between gap-6">
							<h4 className="text-primary uppercase text-base md:text-xl font-bold">
								{t(tKey('labels.share'))}
							</h4>
							{socialIcons.map(socialIcon => (
								<Link target="__blank" to={socialIcon.href as string} key={socialIcon.icon}>
									<img className="cursor-pointer size-[22px]" src={socialIcon.icon} />
								</Link>
							))}
							<Link
								target="__blank"
								to="https://www.tiktok.com/@styling.corner?_t=8rWoHY1RLVI&_r=1">
								<FaTiktok className="size-5 cursor-pointer" />
							</Link>
						</div>
						<div className="max-md:hidden flex flex-col gap-3">
							{headings?.map(({ id, content }, index) => (
								<div
									onClick={() => scrollToElement(id)}
									key={id}
									className="p-3 group hover:bg-[#B790D8] flex gap-2.5 items-center border border-[#B790D8] bg-white hover:border-transparent rounded-full cursor-pointer">
									<p className="font-semibold group-hover:text-white leading-6 text-[#7F9AB2]">
										{index + 1 < 10 ? `0${index + 1}` : index + 1}
									</p>
									<p className="font-semibold leading-6 group-hover:text-white text-primary ">
										{content}
									</p>
								</div>
							))}
						</div>
					</div>
				</div>
				<ArticleSection />
				<Footer />
			</div>
		</AppLayout>
	)
}
