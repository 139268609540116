import { createSlice } from '@reduxjs/toolkit'

const initialState = true

const bannerSlice = createSlice({
	name: 'banner',
	initialState,
	reducers: {
		showBanner() {
			return true
		},
		hideBanner() {
			return false
		}
	}
})

export const { hideBanner, showBanner } = bannerSlice.actions
export default bannerSlice.reducer
